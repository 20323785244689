@import "https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Bevan:ital@0;1&display=swap";
@import "https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

input, button, textarea, select {
  font: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

body {
  min-height: 100vh;
}

.work-sans-font {
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.bevan-regular {
  font-family: Bevan, serif;
  font-style: normal;
  font-weight: 400;
}

.mali-bold {
  font-family: Mali, cursive;
  font-style: normal;
  font-weight: 700;
}

body {
  font-optical-sizing: auto;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
}

:root {
  --color-black: #333;
  --color-white: #fff;
  --color-accent-1: #7209b7;
  --color-accent-2: #4cc9f0;
  --color-accent-3: #f72585;
  --color-accent-4: #f50;
  --color-accent-5: #fa0;
  --text-color: var(--color-black);
  --bg-color: var(--color-white);
  --accent-color: var(--color-accent-1);
  --nav-height: 50px;
  --full-viewport-height: calc(100vh - var(--nav-height));
  --section-padding: 24px;
}

html {
  background-color: var(--color-accent-1);
}

body {
  color: var(--text-color);
  justify-content: stretch;
  display: flex;
}

a {
  color: var(--color-accent-3);
  text-decoration: none;
}

h1 {
  margin-bottom: 2rem;
  font-size: 3rem;
  line-height: 1;
}

h2 {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 400;
}

.centered-paragraphs {
  text-align: left;
  max-width: 60ch;
}

.animate-in-view {
  opacity: 0;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  transform: translateY(20px);
}

.animate-in-view.in-view {
  opacity: 1;
  transform: translateY(0);
}

.layout {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.main-content {
  margin-top: var(--nav-height);
  flex-grow: 1;
}

.shared_nav {
  height: var(--nav-height);
  z-index: 1000;
  background-color: var(--color-accent-1);
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white);
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.shared_nav-left a.shared_nav-logo {
  margin-right: 2rem;
}

.shared_nav-right {
  gap: 2rem;
  display: flex;
}

.shared_nav a {
  color: var(--color-white);
  text-decoration: none;
}

.shared_nav a:hover {
  color: var(--color-accent-2);
}

.footer {
  background-color: var(--color-accent-1);
  color: var(--color-white);
  border-top: 1px solid var(--color-white);
  flex-direction: column;
  align-items: center;
  padding: 1rem 3rem;
  font-size: .875rem;
  display: flex;
}

.footer-main {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.footer-left, .footer-right, .footer-social {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.footer-social {
  margin-top: 1rem;
  display: flex;
}

.footer-left {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.footer a {
  color: var(--color-white);
  text-decoration: none;
  transition: color .3s;
}

.footer a:hover {
  color: var(--color-accent-2);
}

.footer svg {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (width <= 700px) {
  .footer-main {
    flex-direction: column;
    align-items: center;
  }

  .footer-social {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .footer-left, .footer-right {
    flex-direction: column;
    align-items: center;
    margin: .5rem 0;
    position: static;
    transform: none;
  }
}

.screen-height-section {
  height: var(--full-viewport-height);
  justify-content: center;
  align-items: center;
  display: flex;
}

.shared_flexible-height-section {
  padding: var(--section-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  display: flex;
}

.home_section-1 {
  background-color: var(--color-accent-1);
  font-family: var(--font-family-1);
}

.home_section-2 {
  background-color: var(--color-white);
  font-family: var(--font-family-1);
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
  display: flex;
}

.home_section-2-cards {
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

.home_section-2-card {
  text-align: center;
  width: 25%;
  min-width: 300px;
  font-size: 1.5rem;
  display: block;
}

.home_section-3 {
  background-color: var(--color-accent-1);
  color: var(--color-accent-5);
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.intro_section {
  background-color: var(--color-accent-1);
  color: var(--color-white);
  text-align: center;
  padding: 5rem 1rem;
}

.intro_section a#try-now-btn {
  background-color: var(--color-accent-2);
  color: var(--color-black);
  cursor: pointer;
  border: none;
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

.intro_section a#try-now-btn:hover {
  background-color: var(--color-accent-2);
}

.features_section {
  background-color: var(--color-white);
  color: var(--color-black);
  text-align: center;
  padding: 5rem 1rem;
}

.video-container {
  width: 100%;
  max-width: 900px;
  height: auto;
  margin: 0 auto;
  position: relative;
}

.video-container:before {
  content: "";
  padding-top: 56.25%;
  display: block;
}

.video-frame {
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.xibi_about_itself_section {
  background-color: var(--color-accent-1);
  color: var(--color-white);
  text-align: center;
  padding: 5rem 1rem;
}

.xibi_about_itself_section h2 {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.xibi_about_itself_section p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.xibi_about_itself_section .centered-paragraphs {
  text-align: left;
  max-width: 60ch;
  margin: 0 auto;
}

.interactive_section {
  background-color: var(--color-accent-4);
  color: var(--color-black);
  text-align: center;
  padding: 5rem 1rem;
}

.color-picker {
  margin-bottom: 1rem;
}

#interactiveCanvas {
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  width: 100%;
  height: 400px;
  position: relative;
}
/*# sourceMappingURL=index.f9369b1a.css.map */
