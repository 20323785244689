.features_section {
  background-color: var(--color-white); /* White background */
  color: var(--color-black);
  padding: 5rem 1rem;
  text-align: center;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 900px; /* Set max-width */
  height: auto;
  margin: 0 auto; /* Center the video container */
}

.video-container::before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}